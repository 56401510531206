<template>
    <datepicker
        :value="defaultdate"
        :format="this.DatePickerFormat"
        :name="name"
        :placeholder="this.placeholder"
        :language="ja"
        input-class="form-control bg-white"
        :highlighted="highlighted"
    >
    <span slot="afterDateInput" class="mask-icon">
        <i class="fas fa-times clear_birthDate"></i>
    </span>
    </datepicker>
</template>

<script>
    import datepicker from "vuejs-datepicker";
    import { ja } from "vuejs-datepicker/dist/locale";

    export default {
        components: {
            datepicker
        },
        props: {
            name: {
                type: String,
                required: true
            },
            defaultdate: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                DatePickerFormat: "yyyy/MM/dd",
                placeholder: "年/月/日",
                ja: ja,
                highlighted: {
                    days: [6, 0]
                }
            };
        }
    }
</script>
